<template>
    <div class="search-box">
        <div class="ct">
            <input
                type="text"
                placeholder="请输入关键词"
                @click.stop
                @input="getList"
                @focus="getList"
                v-model="searchForm.q"
            />
        </div>
        <div class="right search-btn searchBtnLog" data-id="1" @click.self="handleSearch">立即搜索</div>

        <div class="searchTipsBox" v-if="searchTipsList.length">
            <div
                class="searchTipsItem"
                v-for="(item, index) in searchTipsList"
                @click.stop="searchHandle(item)"
                :key="index"
            >
                <!-- <div class="searchTipsTitle">{{item.title}}</div> -->
                <div class="key">{{ item.gsmc }}<span v-show="item.gpmc!=''">(股票代码：{{ item.gpmc}})</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import { searchEnterprise } from '@/api/enterprise';

export default {
    name: 'searchPanel',
    components: {},
    data() {
        return {
            searchForm: {
                q: '',
            },
            searchTipsList: [],
        };
    },
    mounted() {
        document.body.addEventListener('click', () => {
            this.searchTipsList = [];
        });
    },
    methods: {
        handleSearch() {
            this.$emit('submit', this.searchForm);
        },
        searchHandle(item) {
            // this.searchForm.q = item.gsmc
            // this.handleSearch()
            // this.searchTipsList = []
            this.$router.push({
                path: '/Insight/enterprise/enterpriseHome/reHouseEnterprise/information',
                query: {
                    code: item.gpmc ? item.gpmc : item.gsmc,
                    isMarket: item.gpmc != '' ? 'true' : 'false',
                },
            });
        },
        getList() {
            if (!this.searchForm.q) {
                this.searchTipsList = [];
                return;
            }
            searchEnterprise({
                qylx: this.$parent.searchForm.qylx,
                q: this.searchForm.q,
            }).then((res) => {
                this.searchTipsList = res;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/style/common.scss';
.search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 890px;
    height: 60px;
    margin-top: 40px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(221, 221, 221, 1);
    box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
    border-radius: 60px;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 100%;
        color: #999;
        font-size: 18px;
        border-right: 1px solid #e4e4e4;
    }
    .ct {
        flex-grow: 1;
        .top {
            @include flex(space-around);
            height: 40px;
            border-bottom: 1px solid #eee;
            /deep/ .ivu-select-selection {
                border: none;
            }
        }
        input {
            width: 100%;
            height: 60px;
            padding-left: 20px;
            font-size: 14px;
            border: none;
            background: rgba(249, 249, 249, 1);
            border-radius: 30px 0 0 30px;
            outline: none;
        }
    }

    .searchTipsBox {
        width: 789px;
        position: absolute;
        top: 80px;
        left: 38px;
        background: #fff;
        z-index: 1;
        .searchTipsItem {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            padding: 16px 5px 16px;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
                background: #f5f5f5;
            }
            .searchTipsTitle {
                height: 28px;
                background: rgba(67, 115, 232, 1);
                border-radius: 5px;
                box-sizing: border-box;
                padding: 8px 12px;
                font-size: 14px;
                @include flex-center;
                color: #fff;
            }
            .key {
                font-size: 14px;
                color: #666666;
                margin-left: 9px;
            }
            .item {
                color: #333333;
                font-size: 14px;
                margin-left: 10px;
            }
        }
    }
    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 60px;
        background: rgba(97, 109, 255, 1);
        box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
        color: #fff;
        font-size: 16px;
        border-radius: 0 30px 30px 0;
        cursor: pointer;
    }
}
</style>
