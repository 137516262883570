import { post, get } from '@/utils/http'

export function  enterprise_list(params) {
    return get({ url: '/service-insight/enterprise_dim/dimClassify', params })
}
export function  searchEnterprise(params) {
    return get({ url: '/service-insight/enterprise_dim/searchEnterprise', params })
}
export function  searchStar(params) {
    return post({ url: '/service-insight/land_follow/list', params })
}

