<template>
	<div class="page">
		<div class="page-ct">
			<div class="page-title">企业搜索</div>
			<dataplat-search ref="search" @submit="handleSubmit"></dataplat-search>
		</div>

		<div class="tab">
			<div class="tab-item" :class="{'active' : index == tabIndex}" v-for="(item,index) in tabList" :key="index"
				@click="changeTab(index)">{{item.name}}</div>
		</div>

		<div class="option-box" v-if="tabIndex == 0 || tabIndex == 1">
			<div class="spin_center" v-if="spinShow">
				<Spin></Spin>
			</div>
			<div class="convertibleDetail" v-else>
				<div v-for="(item,index) in dataList" :key="index" style="margin-top: 20px">
					<div class="table-title">{{item.name}}</div>
					<div class="border_line" v-for="(a,b) in item.resultList" :key="b">
						<div class="line" :style="{width:(100/a.length)+'%'}" v-for="(x,y) in a" :key="y">
							<Tooltip theme="light" :content="x.gsmc+(x.gpmc!=''?'('+x.gpmc+')':'')" max-width="300"
								style="width:100%" word-wrap placement="top">
								<a v-if="x.gsjc!=''" @click="_clickToInfo(x.gsmc,x.id,x.gpmc)"
									:class="[x.gpmc == ''?'noGpmc':'']" style="text-decoration: underline">
									{{x.gsjc}}
									<span v-if="x.gpmc!=''">({{x.gpmc}})</span>
								</a>
								<a v-else @click="_clickToInfo(x.gsmc,x.id,x.gpmc)" :class="[x.gpmc == ''?'noGpmc':'']"
									style="text-decoration: underline">
									{{x.gsmc}}
									<span v-if="x.gpmc!=''">({{x.gpmc}})</span>
								</a>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="option-box" v-if="tabIndex == 4">
			<div class="spin_center" v-if="spinShow">
				<Spin></Spin>
			</div>
			<div class="convertibleDetail" v-else>
				<div v-for="(item,index) in dataList" style="margin-top: 20px" :key="index">
					<div class="table-title">{{item.name}}</div>
					<div class="border_line" v-for="(a,b) in item.resultList" :key="b">
						<div class="line" :style="{width:(100/a.length)+'%'}" v-for="(x,y) in a" :key="y">
							<div class="star-box">
								<span>关注</span>
								<!-- <img src="../../assets/image/no-star.png"
                     alt
                     @click="changeStar"
                                v-if="!baseData.star" />-->
								<img src="src/assets/image/star.png" alt @click="changeStar(false,item.name,x)" />
							</div>
							<Tooltip v-if="x.gpdm!=''" theme="light" :content="x.gsmc+(x.gpdm!=''?'('+x.gpdm+')':'')" max-width="300"
								style="width:100%" word-wrap placement="top">
								<a v-if="x.gsjc!=''" @click="_clickToInfo(x.gsmc,x.id,x.gpdm)"
									style="text-decoration: underline">
									{{x.gsmc}}
									<span v-if="x.gpdm!=''">({{x.gpdm}})</span>
								</a>
								<a v-else @click="_clickToInfo(x.gsmc,x.id,x.gpdm)" style="text-decoration: underline">
									{{x.gsmc}}
									<span v-if="x.gpdm!=''">({{x.gpdm}})</span>
								</a>
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		mapState
	} from 'vuex'
	import {
		enterprise_list,
		searchStar
	} from '@/api/enterprise'
	import SearchPanel from "./components/enterpriseHome/searchPanel"
	import {
		post_enterprise_save
	} from '@/api/houseEnterprise'
	import dataReports from '@/mixins/dataReports'

	export default {
		name: 'enterpriseHome',
		mixins: [dataReports],
		components: {
			'dataplat-search': SearchPanel,
		},
		data() {
			return {
				tabIndex: 0,
				tabList: [{
						name: "房企",
					},
					{
						name: "银行",
					},
					// {
					// 	name: "物业",
					// },
					// {
					// 	name: "地产上下游企业",
					// },
					{
						name: "我的关注",
					}
				],
				searchForm: {
					qylx: '房企',
					q: ''
				},
				dataList: [],
				spinShow: true
			}
		},
		mounted() {
			/*if (this.iframeUrl) {
				this.$router.push('/Insight/iframe?rPath=' + this.$route.path)
				return
			}*/
			this.spinShow = true;
			if (this.$route.query.searchVal) {
				this.$refs.search.searchForm.q = this.$route.query.searchVal
				this.handleSubmit(this.$refs.search.searchForm)
			} else {
				this.enterpriseList(this.searchForm);
			}
		},
		computed: {
			...mapState(['iframeUrl'])
		},
		methods: {
			changeStar(status = true, name, item) {
				const pathType = {
					'房企': {
						id: 5
					},
					'银行': {
						id: 6
					}
				}
				const renderType = pathType[name].id;
				post_enterprise_save({
					state: status ? 1 : -1,
					unionId: item.id,
					type: renderType
				}).then(res => {
					this.changeTab(4, true)
				})
			},
			_clickToInfo(name, id, code) {
        var value = {}
        value.name =name
        value.id = id
        value.code = code != "" ? code : name
        value.gpmc = code
				if (!code) {
					return
				}
				if ([0, 4].includes(this.tabIndex)) {
					this.$router.push({
						// path: "/Insight/enterprise/reHouseEnterprise/information",
						path: "/Insight/enterprise/enterpriseHome/reHouseEnterprise/information",
					});
				}
				// this.$store.commit('setComponeyCode', this.selectCode);
				if (this.tabIndex == 1) {
					this.$router.push({
						path: "/Insight/enterprise/bank/bankInformation",
						query: {
							code: code != "" ? code : name,
							isMarket: code != "" ? 'true' : 'false',
						}
					});
				}
				this.$store.commit('setComponeyCode', code);
        this.setHouseEnterpriseData(value)
			},
			setHouseEnterpriseData(value) {
				this.$store.dispatch('reHouseEnterprise/getComponeyData', value)
			},
			changeTab(index, replace = false) {
				if (index == this.tabIndex && !replace) return;
				this.tabIndex = index;
				this.searchForm.qylx = this.tabList[this.tabIndex].name;
				this.spinShow = true;
				if (index == 4) {
					searchStar({
						"current": 0,
						"size": 10000,
						"type": 3
					}).then(data => {
						this.spinShow = false;
						this.dataList = data
						let resultList = [];
						this.dataList.forEach((v) => {
							for (let i = 0, len = v.list.length; i < len; i += 5) {
								resultList.push(v.list.slice(i, i + 5))
							}
							v.resultList = resultList;
							resultList = [];
						})
					})
				} else {
					this.enterpriseList(this.searchForm);
				}
			},
			enterpriseList(params) {
				enterprise_list(params).then(data => {
					this.spinShow = false;
					this.dataList = data
					let resultList = [];
					this.dataList.forEach((v) => {
						for (let i = 0, len = v.list.length; i < len; i += 5) {
							resultList.push(v.list.slice(i, i + 5))
						}
						v.resultList = resultList;
						resultList = [];
					})
				})
			},
			handleSubmit(params) {
				const searchForm = JSON.parse(JSON.stringify(params));
				this.searchForm.q = searchForm.q;
				this.spinShow = true;
				this.enterpriseList(this.searchForm);
			}
		},
	}
</script>
<style lang="scss" scoped>
	@import "~@/style/common.scss";

	.page {
		width: 98%;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding-top: 20px;
		background: #fff;
		overflow: hidden;

		.page-ct {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			min-width: 1200px;
			padding: 0 20px;
		}

		.tab {
			display: flex;
			align-items: center;
			width: 95%;
			margin: 0 auto;
			height: 46px;
			color: #666;

			font-size: 16px;
			border: 1px solid #e3e3e5;
			background-color: #fbfcff;

			&-item {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 126px;
				height: 100%;
				cursor: pointer;

				&.active {
					color: #00b6ff;

					&::after {
						position: absolute;
						width: 100%;
						height: 3px;
						left: 0;
						bottom: 0;
						background-color: #00b6ff;
						content: "";
					}
				}
			}
		}

		.page-title {
			display: inline-block;
			position: relative;
			margin: 100px auto 0;
			font-size: 36px;
			font-style: italic;
			color: rgba(67, 115, 232, 1);

			&::after {
				position: absolute;
				height: 2px;
				width: 100%;
				left: 0;
				bottom: -10px;
				background-color: #4373e8;
				content: "";
			}
		}

		.search-box {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 890px;
			height: 60px;
			margin-top: 40px;
			background: rgba(255, 255, 255, 1);
			border: 1px solid rgba(221, 221, 221, 1);
			box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
			border-radius: 60px;

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 108px;
				height: 100%;
				color: #999;
				font-size: 18px;
				border-right: 1px solid #e4e4e4;
			}

			.ct {
				flex-grow: 1;

				.top {
					@include flex(space-around);
					height: 40px;
					border-bottom: 1px solid #eee;

					/deep/ .ivu-select-selection {
						border: none;
					}
				}

				input {
					width: 100%;
					height: 60px;
					padding-left: 20px;
					font-size: 14px;
					border: none;
					background: rgba(249, 249, 249, 1);
				}
			}

			.right {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 150px;
				background: rgba(97, 109, 255, 1);
				box-shadow: 0px 10px 20px 0px rgba(41, 53, 92, 0.1);
				color: #fff;
				font-size: 16px;
				cursor: pointer;
				height: 60px;
			}
		}

		.tab {
			display: flex;
			align-items: center;
			height: 46px;
			width: 100%;
			margin-top: 50px;
			color: #666;
			font-size: 16px;
			border: 1px solid #e3e3e5;
			background-color: #fbfcff;

			&-item {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 126px;
				height: 100%;
				cursor: pointer;

				&.active {
					color: #00b6ff;

					&::after {
						position: absolute;
						width: 100%;
						height: 3px;
						left: 0;
						bottom: 0;
						background-color: #00b6ff;
						content: "";
					}
				}
			}

			.push-btn {
				@include flex;
				width: 82px;
				height: 28px;
				margin: 0 15px 0 auto;
				color: #666;
				font-size: 14px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(234, 234, 234, 1);
				border-radius: 5px;
				cursor: pointer;
			}
		}

		.choose-box {
			@include flex(flex-start);
			width: 100%;
			min-height: 40px;
			padding: 0 5px;
			background-color: #f0f0f0;

			.title {
				color: #666;
			}

			.des {
				@include flex(flex-start, flex-start);
			}
		}

		.table-box {
			width: 100%;

			.ivu-page {
				position: relative;
				margin-top: 15px;
				text-align: right;
			}
		}
	}

	.convertibleDetail {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		background: #fff;

		h1 {
			font-size: 20px;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
			line-height: 26px;
			margin-bottom: 20px;
		}

		div.more {
			height: 40px;
			background: rgba(250, 250, 253, 1);
			color: #00b6ff;
			line-height: 40px;
			font-size: 14px;
			text-align: center;
			margin-top: 10px;
			cursor: pointer;
		}

		div {
			font-size: 14px;
			color: rgba(51, 51, 51, 1);

			div.table-title {
				@include flex(flex-start);
				padding-left: 20px;
				background: rgba(231, 240, 252, 1);
				font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
				font-weight: bold;
				height: 40px;
				line-height: 40px;
				border: 1px solid rgba(214, 215, 217, 1);
			}

			div {
				@include flex;
				height: 40px;
				line-height: 40px;

				.line-title:nth-of-type(1) {
					border-left: 1px solid rgba(214, 215, 217, 1);
				}

				.line-title {
					background: rgba(250, 250, 253, 1);
					width: 414px;
					border-right: 1px solid rgba(214, 215, 217, 1);
					border-bottom: 1px solid rgba(214, 215, 217, 1);
				}

				.line-content {
					background: #fff;
					width: 414px;
					border-right: 1px solid rgba(214, 215, 217, 1);
					border-bottom: 1px solid rgba(214, 215, 217, 1);
				}
			}
		}
	}

	.border_line {
		border-left: 1px solid rgba(214, 215, 217, 1);
		border-right: 1px solid rgba(214, 215, 217, 1);
		border-bottom: 1px solid rgba(214, 215, 217, 1);
		width: 100%;

		.noGpmc {
			color: black;
			text-decoration: none !important;
			cursor: default;
		}
	}

	.line {
		width: 100%;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-left: 1px solid rgba(214, 215, 217, 1);
		background: #fff;

		border-bottom: 1px solid rgba(214, 215, 217, 1);
		white-space: nowrap;
		overflow: hidden;
		position: relative;

		.star-box {
			cursor: pointer;
			position: absolute;
			top: 0px;
			left: 20px;
			display: flex;
			align-items: center;
			z-index: 10;

			span {
				margin-right: 10px;
			}
		}
	}

	.border_line .line-title:nth-of-type(1) {
		border-left: none;
	}

	/*  .line {
        width: 100%;

        height: 40px;
        text-align: center;
        line-height: 40px;
        border-left: 1px solid rgba(214, 215, 217, 1);
        .line-title:nth-of-type(1) {
            border-left: 1px solid rgba(214, 215, 217, 1);
        }
        .line-title {
            background: rgba(250, 250, 253, 1);
            flex: 1;
            height: 100%;
            border-right: 1px solid rgba(214, 215, 217, 1);
            border-bottom: 1px solid rgba(214, 215, 217, 1);
        }
        !*.line-content {
            background: #fff;
            flex: 1;
            height: 100%;
            border-right: 1px solid rgba(214, 215, 217, 1);
            border-bottom: 1px solid rgba(214, 215, 217, 1);
            white-space: nowrap;
            overflow: hidden;
        }*!
    }*/

	.spin_center {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
	}
</style>
